/**
 * Stores build information. The string literals are replaces at compile time by `set_version.js`.
 */
export const buildInfo = {
    versionNumber: "0.1.0",
    versionName: "nightly 2023-01-05",
    versionChannel: "nightly",
    buildDate: "2023-01-05T00:14:28.740Z",
    commitHash: "4919e2a638701d6606d2d5b1e01b535de62da98f",
};
